import React from 'react';
import {
    Box,
    InputLabel,
    FormControl,
    NativeSelect,
} from '@mui/material';
import { useField } from 'formik';  


export default function CustomTimePicker(props) {
    const { label, data, name, id, required, selectLabel, ariaDescribedby, ...rest } = props;
    const [field, meta] = useField(props);
    const { value: selectedValue } = field;
    const currentTime = new Date();
    const firstAvailableSlot = new Date(new Date().setHours(9, 0, 0, 0));
    const eliminatedTimeSlots = Math.floor((((Date.parse(currentTime)-Date.parse(firstAvailableSlot))/3600000/*Time in hours since first appt*/)/.25/*4 time slots an hour*/)+3/* ensures the earliest viewable appt is >= 30 min away*/);
    const availableTimeSlots = data.toSpliced(0, eliminatedTimeSlots);
    const labelID = id + '-label';
    delete rest.formRef;
    const onChange = (e) => {
        console.log('onChange ', e.target.value); // 10:45
        field.onChange && field.onChange(e); 
        if (props.passedTime) props.passedTime(e.target.value);
        
    };
    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl {...rest} fullWidth variant="outlined" className="uopx-select uopx-native-select">
                <InputLabel name={name} id={labelID} htmlFor={id}>
                    {label}
                </InputLabel>
                <NativeSelect
                    disableUnderline inputProps={{
                        'aria-label':"Schedule Contact Time *",
                        'aria-required': true,
                        "aria-describedby": ariaDescribedby
                    }} name={name} id={id} {...field} labelid={labelID} onChange={onChange} value={selectedValue ? selectedValue : ''}
                >                                    
               <option key={0} value=''/> {/* Makes the first option blank and no value */}                                               
                    {/*If isTodayDatePicked flag is true use availableTimeSlots instead of data */}
                    {props.isTodayDatePicked === false ? (
                        <>
                            {data.map((item, index) => {            
                                return (                        
                                    <option key={index} value={item.value}>
                                        {item.label}
                                    </option>
                                )                                                                  
                            })}
                        </>
                    ) : 
                    ( 
                        <>
                            {availableTimeSlots.map((item, index) => {            
                                return (                        
                                    <option key={index} value={item.value}>
                                        {item.label}
                                    </option>
                                )                                                                  
                            })}
                        </>
                    )}                  
                </NativeSelect>
            </FormControl>
        </Box>
    );
}

//class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled css-1sumxir-MuiFormLabel-root-MuiInputLabel-root"


//export default SelectField;