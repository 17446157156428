import React from 'react';
import {
    Box,
    FormControl,
    NativeSelect,
} from '@mui/material';
import { useField } from 'formik';

export function UOPDatePickerDropdown(props) {
    const { label, data, name, id, required, selectLabel, ariaDescribedby, ...rest } = props;
    const [field, meta] = useField(props);
    const { value: selectedValue } = field;
    const currentDate = new Date();
    const currentLocaleDate = new Intl.DateTimeFormat('en-US', {year: "numeric", month: "numeric", day: "numeric"}).format(currentDate);
    const datesAvailable = [];
    const options = {
        weekday: "long",
        year: "numeric",
        month: "numeric",
        day: "numeric",
    };
    let dayHasBeenSkipped = false;
    let timesDayHasBeenSkipped = 0;
    let todaysDateWasPicked;
    let year = new Date().getFullYear();
    let christmas = `${year}-12-25`;
    let christmasEve = `${year}-12-24`;
    function isWeekend(date) {
        let UTCDate = new Date(date).toISOString().split('T')[0]; // "2024-11-20"
        if (UTCDate === "2024-11-28" || UTCDate === "2024-12-23" || UTCDate === "2024-12-24" || UTCDate === "2024-12-31") {
            console.log("inside holidays to be skipped section");
            dayHasBeenSkipped = true;
            timesDayHasBeenSkipped += 1;
            return true;
        } else if (UTCDate === christmasEve) {
            console.log("inside christmas eve section");
            dayHasBeenSkipped = true;
            timesDayHasBeenSkipped += 1;
            return new Date(date).getDay() === 0 || new Date(date).getDay() === 6
        } else if (UTCDate === christmas) {
            console.log("inside christmas section");
            dayHasBeenSkipped = true;
            timesDayHasBeenSkipped += 1;
            return true;
        } else if (new Date(date).getDay() === 0) {
            console.log("inside any other date section");
            dayHasBeenSkipped = true;
            timesDayHasBeenSkipped += 1;
            return true;
        } else {
            return false;
        }
    }
    for (let i = 0; i < 7; i++) {
        let date = new Date(); // UTC date Object;
        let labelDate = new Date(); // UTC date Object;
        if (props.isValidDay === false) {
            date = new Date(new Date(date).setDate(new Date(date).getDate() + (i + 1)));
            labelDate = new Date(new Date(labelDate).setDate(new Date(labelDate).getDate() + (i + 1)));
        } else {
            date = new Date(new Date(date).setDate(new Date(date).getDate() + i));
            labelDate = new Date(new Date(labelDate).setDate(new Date(labelDate).getDate() + i));
        }
        labelDate = new Intl.DateTimeFormat('en-US', options).format(labelDate); // "Monday, 11/18/2024"
        let key;
        if (dayHasBeenSkipped === false) {
            key = i;
        } else if (dayHasBeenSkipped && timesDayHasBeenSkipped === 1) {
            key = i - 1
        } else {
            key = i - 2
        }
        let value = new Intl.DateTimeFormat('en-US', {year: "numeric", month: "numeric", day: "numeric"}).format(date);
        //let value = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
        //value = new Date(value).toISOString().split('T')[0]; // 2024-11-20
        isWeekend(new Date(date)) ? console.log(`${date} is a day with no one available to take calls`) : datesAvailable.push({ key: key, value: value, label: labelDate });
    }
    const finalDatesAvailable = datesAvailable.length === 6 ? datesAvailable.toSpliced(5, 1) : datesAvailable.length === 7 ? datesAvailable.toSpliced(5, 2) : datesAvailable.toSpliced(5); // { "key": 0, "value": "2024-11-20", "label": "Wednesday, 11/20/2024" }
    console.log(finalDatesAvailable);
    const labelID = id + '-label';
    delete rest.formRef;
    const onChange = (e) => {
        console.log('onChange ', e.target.value); // 2024-11-14
        field.onChange && field.onChange(e);
        //e.target.value == currentLocaleDate ? console.log("todaysDateWasPicked = true") :console.log("todaysDateWasPicked = false");
        e.target.value == currentLocaleDate ? todaysDateWasPicked = true : todaysDateWasPicked = false;
        if (props.passedDate) props.passedDate(e.target.value, todaysDateWasPicked);
    };
    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl {...rest} fullWidth variant="outlined" className="uopx-select uopx-native-select" id="sch-call-formControl">
                <NativeSelect
                    disableUnderline inputProps={{
                        'aria-label': "Schedule Contact Date *",
                        'aria-required': true,
                        "aria-describedby": ariaDescribedby
                    }} name={name} id={id} {...field} labelid={labelID} onChange={onChange} value={selectedValue ? selectedValue : ''}
                >
                    <option key={0} value='' /> {/* Makes the first option blank and no value */}
                    {finalDatesAvailable.map((item/* , index */) => {
                        return (
                            <option key={item.key + 1} value={item.value}>
                                {item.label}
                            </option>
                        )
                    })}
                </NativeSelect>
            </FormControl>
        </Box>
    );
}