import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { createGenerateClassName, StylesProvider, withStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material/';
import { Formik, Form } from "formik";
import date from 'date-and-time';
//import styles from '../layout/styles/MobileFormStyles';
//import initialEmailFormValues from './EmailusFormInitialValues';
//import styles from '../inline/SubscribeStyles';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import getIPAddress, { submitForm, mapAdditionalFields, feedDataToSessionStorage, loadLocalStorageData, unescapeHTML, userData, fireEvent, fireAnalyticsEvent, fireNonRFISubmitEvent, getmcid, profanityCheck } from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocusDialog';
import Button from '../layout/MaterialComponents/button/Button';
import SingleCheckbox from '../layout/MaterialComponents/checkbox/SingleCheckbox';
//import checkmark from '../layout/images/checkmark.svg';
import { initValues, processJSONParameter } from '../common/configTools';


const formRef = {};
let defaultTextHTML = '<h2 id="dialogTitle-SGbanner" class="newOneForm-title" style="display:flex;"><span id="originalTitle">Free Scholarships and Savings Guide</span><span id="modTitle" style="align-items:center; justify-content: center; margin-top:0;">Explore your savings</span></h2>';
let defaultDescriptionTextHTML = '<div id="dialogDesc-SGbanner" class="newOneForm-SubTitle" style="font-weight: 400"><span id="originalDesc">Explore our scholarship opportunities, external scholarships, the application process and deadlines — and even more savings opportunities.</span> <span id="modDesc">Get the <strong style="color:#307746;">Scholarships and Savings Guide</strong> to help you learn all the ways you can save on your education.</span></div>';
let defaultCTA = '<div id="buttonCTA-SGbanner" class="newOneForm-cta" style="display:flex;"><span id="originalCTA">Request my free guide</span><span id="modCTA" style="align-items:center; justify-content: center; margin-top:0;">Request my guide</span></div>';
 const initialEmailFormValues = {
    fullName: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    flex_more_info__c: '',
    flex_Info_Requested__c: 'something_else',
    appContext: 'EMAILCAPTURE',
    mcid: '',
    useCaseContext: 'NEW',
    flex_EmailCaptureSource: 'SCHL',
    userAgent: '',
    campaign: 'PHXEDU10',
    source: 'INET',
    requestingIP: '',
    requestingUrl: '',
    disclaimer: 'This information is available on <a tabindex="0" href="https://www.phoenix.edu/">phoenix.edu</a> but has been organized into one central source for you to reference quickly and easily. By submitting this form, you consent to receiving University of Phoenix emails about our educational programs and services. Visit our <a tabindex="0" href="https://www.phoenix.edu/copyright-legal/privacy_policy.html">Privacy Policy</a> for more information.',
  };
const disclaimerText = initialEmailFormValues.disclaimer;
const ecidValue = getmcid();
let preProspectInfoData = {};
let device;
let tcValue = '';
//const isTabletView = window.innerWidth <= 990;
const isMobileView = window.innerWidth <= 640;
let formStep;
let formType;
let formName;
let formLocation;
let scrollOffSetStepper = -150;

function srSpeak(text, priority) {
    try{
        var el = document.createElement("div");
        var id = "make-a-screen-reader-talk-" + Date.now();
        el.setAttribute("id", id);
        el.setAttribute("aria-live", priority || "polite");            
        el.classList.add("visually-hidden");
        document.body.appendChild(el);
        
        window.setTimeout(function () {
            document.getElementById(id).innerHTML = text;      
        }, 100);
        
        window.setTimeout(function () {
            if (document.getElementById(id)){
                document.body.removeChild(document.getElementById(id));
            }
        }, 1000);
    } catch(e){
        console.log ("error", e);
        throw e;
    }
}

function trapFocus(element, focusElmtValue) {
    var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])');
    var firstFocusableEl = focusableEls[focusElmtValue];  
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    var KEYCODE_TAB = 9;
    element.addEventListener('keydown', function(e) {
      var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
      if (!isTabPressed) { 
        return; 
      }
      if ( e.shiftKey ) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
            e.preventDefault();
          }
        } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
            e.preventDefault();
          }
        }
    });
  }

class ScholarshipBladeBannerModal extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: initialEmailFormValues,
            formRef: null,
            currentStep: 1,
        };
        this.state = this.initialState;
    }
    reset() {
        this.setState(this.initialState);
    }

    componentDidMount() {
        const { props } = this;
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href; 
        initialValues.flex_EmailCaptureSource = 'SCHL';
        initialValues.thankyouPageUrl= '';
        initValues(initialValues, this.props); 
        loadLocalStorageData(initialValues);
        this.setState({ initialValues }); 
        getIPAddress(this.updateIPAddress);
        if (isMobileView){device = 'Mobile';}else {device = 'Desktop';}
        const { currentStep } = this.state;
        formStep = currentStep;
        formType = 'Preprospect Zero Party';
        formLocation = 'Zero Party Scholarship Blade';
    	formName = this.props.formname || 'Scholarship Blade Modal ';
        formName = formName + device;
        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formLocation"] = formLocation;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }
        // BLUR EVENT
        // document.querySelector('input[name="fullName"]').addEventListener("blur", function() { 
        //     setTimeout(function () {
        //         if (document.querySelector('[id^="fullName"][id$="-helper-text"]')) {
        //             document.querySelector('input[name="emailAddress"]').focus()
        //         }
        //     }, 300);
        // });

        // KEYDOWN EVENT
        if (document.querySelector('#modalrfiSG-banner .abandonment input[name="fullName"]')) {
            document.querySelector('#modalrfiSG-banner .abandonment input[name="fullName"]').addEventListener("keydown", function(e) {
                if(e.keyCode == 9 || e.key === 'Tab') {
                    setTimeout(function () {
                        if (document.querySelector('#modalrfiSG-banner [id^="fullName"][id$="-helper-text"]')) {
                            document.querySelector('#modalrfiSG-banner input[name="emailAddress"]').focus()
                        }
                    }, 300);
                }
            });
        }
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.requestingIP = ipAddress;
        this.setState({ initialValues });    
    }

    setAnalytics = (formname) => {
           
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireEvent('PreprospectFormStart', 'Zero Party', formName, formType, 'Start scholarship blade modal', formLocation);
            this.setState({ rfi_begin: true });
        }
      }    
      
    isTransferCredit = (event, checked) => {
        formRef.current.values.flex_Transfer_Credits_from_ValueExchange__c = checked;
        if (checked === true) {
            tcValue='checked';
        }
        if (checked === false) {
            tcValue='unchecked';
        }      
    }

    handleSubmit = async values => {
      if (document.activeElement.id === "SGBannerModalSubmit") {
        if (((values.fullName).trim().indexOf(' ')) > 0) {
            const i = (values.fullName.trim()).indexOf(' ');
            values.firstName = (values.fullName.trim()).substring(0, i); 
            values.lastName = (values.fullName.trim()).substring(i + 1);
        }    
        this.setState({ initialValues: values });  
        window.uopSegment&&window.uopSegment.identify({'firstName':values.firstName, 'lastName':values.lastName, 'email': values.emailAddress});
        window.uopSegment&&window.uopSegment.track('Scholarship Blade Modal Submitted', {'firstName':values.firstName, 'lastName':values.lastName, 'email': values.emailAddress, 'analytics': {'mcid': ecidValue}});
        //window.uopxDataLayer&&window.uopxDataLayer.track('PreProspectInfoSubmitted', {'componentName' : 'RequestInfo', 'name': formName, 'type': formType, 'formInformation': {'profileDetails':{'firstName':values.firstName, 'lastName':values.lastName, 'email':values.emailAddress}}, 'external': {'segmentAnonymousId': window.analytics.user().anonymousId(), 'segmentUserId': window.analytics.user().id()}});
        srSpeak('email submitted. Thanks for requesting the Scholarships and Savings Guide. Download your pdf guide now. Or access the link in our email.');
        //submitForm(values, this.props);
        //fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
        //this.setState({ isSubmit: true, currentStep:2}); 
        mapAdditionalFields(values);
        feedDataToSessionStorage(values);
        values.submissionTimestamp = date.format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ');
        Object.keys(values).map(k => values[k] = typeof values[k] === 'string' ? values[k].trim() : values[k]);
        Object.keys(values).forEach(k => (values[k] === '' || values[k] === undefined || values[k] === null) && delete values[k]);

        let postURL = process.env.REACT_APP_PHXEDU_PROD_POST_URL;
        if (window.location.host !== 'www.phoenix.edu') {
            postURL = process.env.REACT_APP_PHXEDU_STAGE_POST_URL;
        }
        
        try {
            const response = await fetch(postURL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
            });

        this.setState({
            initialValues: values,
            isSubmit: true,
            currentStep:2,
            message: null,
            // message: <div style={styles.success}>{responseJSON.message || 'No Message Given'}</div>,
        });
            //setTimeout(function(){trapFocus (document.querySelector('#modalrfiSG-banner'), 0);}, 1000);
            //setTimeout(function(){ fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType); }, 200);
            //fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType);
            if (window.uopxDataLayer && window.uopxDataLayer.track) {
                window.uopxDataLayer&&window.uopxDataLayer.track('PreprospectFormSubmit', {'componentName' : 'Zero Party', 'name': formName, 'type': formType, 'text': 'Submit scholarship blade modal', 'location': formLocation, 'formInformation': {'profileDetails':{'firstName' :values.firstName, 'lastName':values.lastName, 'email':values.emailAddress}, 'formDetails':{'campaign' : 'Scholarship Transfer Credits', 'campaignOption' : tcValue},},});
            }
        } catch (e) {
        this.setState({
            message: <div className="">{e.message || e}</div>,
        });
        }
        if (window.FS) {
            preProspectInfoData.source= "ScholarshipGuideBladeForm";
            //calling FS and passing the tracking data set
            window.FS.event('Submitted PreProspectInfo', preProspectInfoData);
        }                 
        this.scrollToElement(document.getElementById('flex-div-SG-banner'), scrollOffSetStepper); 
    
        //document.querySelector('#announce').innerHTML = 'email submitted';
        //if (document.querySelector('.btn-close')) {
            document.querySelector('#downloadcta-SGbanner').focus(); 
            trapFocus(document.querySelector('#modalrfiSG-banner'), 0);
        //}
      }   
    }

    scrollToElement(ele, yOffset = scrollOffSetStepper) { // USE DEFAULT VALUE FOR STEPPER
        const y = ele.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }
    
    validateScholarshipBladeModalForm(values) {
        const errors = {};
        if (!values.fullName) {
            errors.fullName = 'First and Last name requires a value';
          } else if (values.fullName.trim().indexOf(' ') === -1) {
            errors.fullName = 'Please enter a First and Last name';
          } else if (!profanityCheck(values.fullName)) {
            errors.fullName = 'Full Name appears invalid';
          }
        if (!values.emailAddress) {
          errors.emailAddress = 'Email Address requires a value';
        }
        if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          errors.emailAddress = 'Email address appears invalid';
        }
        return errors;
    }

    /*modalClosebtn = () => {
        this.setState({ isSubmit: false, currentStep:1});
    } */

    handleDownload =(e)=> {
        //window.open('https://www.phoenix.edu/content/dam/edu/tuition-financial-aid/doc/scholarship-guide.pdf', "_blank", "noreferrer");
        const pdfUrl = 'https://www.phoenix.edu/content/dam/edu/tuition-financial-aid/doc/scholarship-guide.pdf';
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.role = 'link'
        link.target='_blank';
        link.download = "scholarship-guide.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.uopxDataLayer.track("PreprospectDownloadButtonClicked",{
            'componentName' : 'Zero Party',
            'name': formName, 
            'type': formType,
            'text': 'Download scholarship blade modal',
            'location': formLocation
        });
    }
    
    keydownDownload = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            this.handleDownload;
        }         
    }

    modalClose = () => {
        //const { initialValues } = this.state;
        let modal = document.querySelector("#modalrfiSG-banner");
        let overlay = document.querySelector("#overlay-div");
        let exitModal = document.querySelector("#modalrfiSG-banner.exitmodal");
        if (overlay !== null){
            //modal.classList.add("hidden");
            document.querySelector('#overlay-div.hidden')?console.log(".overlay already contains 'hidden'"):overlay.classList.add("hidden");
        }
        if (modal !== null){
            if (exitModal !== null) {
                exitModal.classList.add("hidden");
            } else{
                modal.removeAttribute("style");
                modal.removeAttribute("tabIndex");
                if (document.querySelector('#modalrfiSG-banner .mobileFormContainer').style.length > 0 ){
                    document.querySelector('#modalrfiSG-banner .mobileFormContainer').removeAttribute("style");
                }
                if (document.querySelector('#modalrfiSG-banner .abandonment').style.length > 0 ){
                    document.querySelector('#modalrfiSG-banner .abandonment').removeAttribute("style");
                }
                if (document.querySelector('#modalrfiSG-banner .columnContainer')){
                    document.querySelector('#modalrfiSG-banner .columnContainer').classList.remove("SGModalOpen");
                }
                if (isMobileView) {
                    document.querySelector("#modalrfiSG-banner .nothanks").classList.add("hidden");
                } else { 
                    document.querySelector("#modalrfiSG-banner .btn-close").classList.add("hidden");
                }
            }
        }
        window.uopxDataLayer.track("PreprospectModalClose",{
            'componentName' : 'Zero Party',
            'name': formName, 
            "type": formType,
            "text": "Close scholarship blade modal",
            "location": formLocation,
        });
        this.setState({ isSubmit: false, currentStep:1}); 
    };

    keydownClose = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32){
            this.modalClose();
        }         
    }
      
    render() {
        const { classes } = this.props;
        const { initialValues,  isSubmit} = this.state;

        let titleText = this.props.title || defaultTextHTML;
        let descriptionText = this.props.description || defaultDescriptionTextHTML;
        let cta = this.props.cta || defaultCTA;
        //let cta = this.props.cta || 'Request my guide';
        //if (isTabletView){cta = 'Request my guide';}
        let modal = document.querySelector("#modalrfiSG-banner");
        const width = window.innerWidth;
        let thankyouMessageTitle = this.props.thankyouMessageTitle || '<h2 id="thanksdialogtitle-SGbanner" class="mainHeader" style="display:flex; align-items:center; justify-content: center; text-align:center; margin-top:0;">Thanks for requesting the Scholarships and Savings Guide</h2>';
        let thankyouMessageDescription = this.props.thankyouMessageDescription || '<div id="thanksdialogdesc-SGbanner" class="newOneForm-SubTitle thanks" style="display:flex; align-items:center; justify-content: center; margin-top:0;">Download your pdf guide now. Or access the link in our email.</div>';

        return (    
            <div className={classes.root}>
            {/* <UOPHeader /> */}
                <section className="abandonment">
                    <Grid container justifyContent='center' className="mobileFormContainer">
                    <Grid container md={12} sm={12} lg={12} xs={12} className="maxWidthContainer-oneStepForm">
					    {/*	<Grid item md={12} sm={12} lg={12} xs={12} >
						<div>{unescapeHTML(titleText)}</div>
                        <div className="newOneForm-SubTitle" style={{ fontSize: '18px', fontWeight: 700 }}>Email us &mdash; and we’ll get the ball rolling.</div>
                        <div className="reqinfo" > * Required information </div>
						</Grid>   */}
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => this.validateScholarshipBladeModalForm(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    <Form className="mobile-form scholarshipModal"
                                        aria-labelledby={isSubmit? "thanksdialogtitle-SGbanner thanksdialogdesc-SGbanner" : "dialogTitle-SGbanner dialogDesc-SGbanner"}
                                        style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                        <Grid container  className="columnContainer">
                                            
                                    {!isSubmit
                                        ? (
                                            <>
                                                <Grid item md={12} sm={12} lg={6} xs={12} id="SGbannerIMG" className="margin-OneStepForm modalimgSGbanner modalimg rfiabandonment" role="img" aria-label="Cover of the Scholarships and Savings Guide with an image of smiling graduate dressed in a cap and gown.">
                                                    <div className=""> </div>
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} id="SGbannerFORM" className ="margin-OneStepForm rfiabandonmentcopy">
                                                    {unescapeHTML(titleText)}
                                                    {unescapeHTML(descriptionText)}
                                                    <Typography variant='body2' component='div' className="reqinfo"> * Required information </Typography>
                                                    <MaterialInput
                                                        name="fullName"
                                                        id="fullName-SGbanner"
                                                        label="First and Last name *"
                                                        ariaRequired="true"
                                                        ariaLabel="Enter your full name"
                                                        variant="outlined"
                                                        className='uopx-input footeremail modalfield'
                                                        onClick={this.handleAnalyticsRFIFormStart}
                                                    />
                                                    <MaterialInput name="emailAddress" id="emailAddress-SGbanner" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" className='uopx-input footeremail modalfield' />
                                                    <SingleCheckbox
                                                        id="flex_Transfer_Credits_from_ValueExchange__c"
                                                        className='ways2save-checkbox'
                                                        label="I have prior college credits."
                                                        ariaLabel="I have prior college credits."
                                                        checkboxName="flex_Transfer_Credits_from_ValueExchange__c"
                                                        parentCallback={this.isTransferCredit}
                                                    />
                                                    <Grid item xs={12} className="SGMobImgContainer" style={{alignSelf:'center'}}>
                                                        <img loading="lazy" src="/content/dam/edu/request-info/img/mobileScholarshipBladeImageBook.png" alt="Cover of the Scholarships and Savings Guide with an image of smiling graduate dressed in a cap and gown." id="SGMobImg" className=""/>
                                                    </Grid>
                                                    <div className="disclaimer"> {unescapeHTML(disclaimerText)} </div>
                                                    <Button name='submit' size='large' color='secondary' type='submit' id="SGBannerModalSubmit" className='rfi-btn-submit submit-btn'> {unescapeHTML(cta)} </Button>
                                                    {isMobileView && <Button size='small' variant = 'Secondary' color='secondary' id="SGBannerNoThanks" onMouseDown={this.modalClose} onKeyDown={this.keydownClose} className='nothanks' style={{color:'#5e7079', margin:'auto', marginTop:'16px'}}> No thanks </Button> }
                                                </Grid>
												<ErrorFocus />
                                            </>
                                        ):(
                                            <>  
                                                <Grid item md={12} sm={12} lg={6} xs={12} id="SGbannerIMG" className ="margin-OneStepForm modalimg-thanks rfiabandonment" role="img" aria-label="Image of a piggy bank and a stack of gold coins.">
                                                    <div className=""> </div>
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} id="SGbannerTyForm" className ="margin-OneStepForm modalthankscopy" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                <div className ="rfiabandonmentcopy" id="rfiSGbannercopy">
                                                    {unescapeHTML(thankyouMessageTitle)}
                                                    {unescapeHTML(thankyouMessageDescription)}
                                                    <Grid item xs={12} className="SGMobImgContainer" style={{alignSelf:'center'}}>
                                                        <img loading="lazy" src="/content/dam/edu/request-info/img/mobileScholarshipBladeTYpagePiggy.png" alt="Cover of the Scholarships and Savings Guide with an image of smiling graduate dressed in a cap and gown." id="SGMobImg" className=""/>
                                                    </Grid>
                                                    <div className="SGbanner-downloadcta" style={{ padding: '1.5rem 3rem'}} >
                                                        <div>
                                                            <Button size='large' id='downloadcta-SGbanner' color='secondary' onClick={this.handleDownload} onKeyDown={this.keydownDownload} className='rfi-btn-submit btnclose'>Download now</Button>
                                                        </div>              
                                                        <div>
                                                            <Button size='small' variant='Secondary' color='secondary' onMouseDown={this.modalClose} onKeyDown={this.keydownClose} className="hidden" style={{color:'#5e7079', margin:'auto', fontSize:'18px', marginTop:'16px'}}> Close </Button>
                                                        </div>
                                                    </div>
                                                </div>    
                                                </Grid>
                                            </> 
                                          )}
                                          <button id="SGbanner-close-modal" aria-label="Close dialog" title="Close" onClick={this.modalClose} onKeyDown={this.keydownClose} type="button" class="btn-close btn-sg-mb hidden"></button>
                                          </Grid>
                                        </Form> 
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
        {/*    <Footer /> */}
            </div>
        );
    }
}

ScholarshipBladeBannerModal.defaultProps = {
    classes: {},
};

ScholarshipBladeBannerModal.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

const generateClassName = createGenerateClassName({
    productionPrefix: 'sgBannerForm',
});

export default function (props) {
    return (
        <StylesProvider generateClassName={generateClassName}>
            <ScholarshipBladeBannerModal {...props} />
        </StylesProvider>
    );
}